<template>
    <div class="px-3 md:px-0 pt-4">
            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6">
                Promo
            </p>

            <div v-if="promoList.length > 0">
                
                <div class="grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-4 gap-2 md:gap-10 sm:gap-2 my-3 text-gray-600 hover:text-gray-800 transition-all">

                    <div @click="gotoPromoDetail(data.id_promo)" class="transition-all hover:bg-gray-100 cursor-pointer shadow bg-white relative rounded-lg px-4 py-4" v-for="(data, index) in promoList" :key="index">
                        <img :src="data.thumbnail" class="w-full rounded-lg" />
                        <div class="flex space-x-5 items-center mt-2">
                            <div class="flex-grow flex-wrap ">
                                <h2 class="text-lg text-black font-semibold">{{ data.judul }}</h2>
                                <p>{{ data.kategori.judul }}</p>
                            </div>
                        </div>

                        <div class="flex mt-2 items-center">
                            <div class="flex-grow">
                                <p class="font-medium text-gray-500">Potongan</p>
                                <p class="text-lg text-black font-semibold">Rp. {{ data.jumlah_potongan | moneyFormat }}</p>
                            </div>
                            <div class="flex-grow bg-blue-500 px-3 py-2 text-white font-medium text-lg rounded-lg relative font-mono" @click="copyPromoCode(data.kode_promo)">
                                {{ data.kode_promo }}
                                <div class="absolute right-1 bg-blue-600 top-1 bottom-1 text-sm w-10 rounded-lg flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="animate-pulse" v-if="isLoading">
                <div class="flex bg-white py-2 px-3 border rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                    <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                    </div>
                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <div class="h-6 bg-gray-100 rounded"></div>
                        <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                        <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                    </div>
                </div>
            </div>
            
            <div v-if="!isLoading && promoList.length == 0" class="text-center w-full mt-6" >
                <div class="w-2/3 px-5 mx-auto">
                    <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                    <p class="mt-1 text-gray-800 font-semibold">Maaf, belum ada promo</p>
                </div>
            </div>
    </div>
</template>
<script>
import { errorHandler, showErrorNotif, copyToClipboard } from '../../helpers/Tools';
export default {
    name : 'PromoIndex',
    data(){
        return {
            isLoading: true,
            promoList: [],
            page     : 1,
            isEmpty  : false
        }
    },
    mounted() {
        this.getPromoList(this.page);
        this.scroll();
    },
    destroyed() {
        window.onscroll = () => {
        }
    },
    methods : {
        gotoPromoDetail(id_promo) {
            this.$router.push({ 
                name: 'PromoDetail', 
                params: { 
                    id_promo           : id_promo
                },
            });
        },
        copyPromoCode (string) {
            copyToClipboard(this, string);
        },
        scroll() {
            window.onscroll = () => {
                const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                if (bottomOfWindow && !this.isEmpty) {
                    this.getPromoList()
                }
            }
        },
        async getPromoList() {
            this.isLoading = true;
            try {
                let res = await this.$store.dispatch("getPromo", {
                    page: this.page
                });
                this.isLoading = false;
                let result = res.data;
                if(result.status == 'success') {
                    if(result.data.data.length == 0) {
                        this.isEmpty = true;
                    }

                    if(this.page == 1){
                        this.promoList = result.data.data;
                    }else{
                        var ids = new Set(this.promoList.map(d => d.id_promo));
                        var merged = [...this.promoList, ...result.data.data.filter(d => !ids.has(d.id_promo))];
                        this.promoList = merged;
                    }
                    this.page++;

                } else {
                    this.isEmpty = true;
                    showErrorNotif(this, result.message);
                }
            } catch (error) {
                this.isLoading = false;
                errorHandler(this, error);
            }
        }
    }
}
</script>